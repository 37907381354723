<template>
  <!-- 顧客備註 -->
  <div class="tw-container">
    <div class="p-md-2">
      <div class="mb-3">
        <span class="fw-bold tw-text-size18 tw-border-start me-2">
          顧客備註(針對此顧客做描述，顧客結單時不會看到)
        </span>
        <button class="tw-btn tw-btn-success" @click="updateParticipantNote">
          儲存備註
        </button>
      </div>
      <!-- 備註 -->
      <div class="form-floating">
        <textarea
          class="form-control"
          placeholder="備註"
          id="ParticipantNote"
          style="height: 150px"
          maxlength="300"
          v-model="participantInfo.note"
        ></textarea>
        <label for="ParticipantNote">顧客備註(300字)</label>
      </div>
    </div>
  </div>
  <hr class="mx-md-4 mx-3 my-md-0 my-3" />
  <!-- 收件資訊 -->
  <ReceivingInfo :participantId="participantId"></ReceivingInfo>
</template>
<script>
// components
import ReceivingInfo from '../../../components/receipingInfo/ReceipingInfo.vue'
export default {
  components: {ReceivingInfo},
  data() {
    return {
      // data
      serverToken: '',
      participantId: 0,
      participantInfo: {},
    }
  },
  created() {
    this.initialization()
    this.getParticipantInfo()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.participantId = parseInt(this.$route.params.participantId)
    },
    // 取得participant資訊
    getParticipantInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 13,
          ids: [this.participantId],
          methods: '{}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.participantInfo = participantInfo
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 儲存顧客備註
    updateParticipantNote() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateParticipantNoteApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.participantId,
        attributes: {
          setNote: this.participantInfo.note
        },
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateParticipantNoteApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getParticipantInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>